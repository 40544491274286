<template>
  <div class="mainform">
    <div class="form formlabel">
      <el-form  class="content bascform" ref="addpayplan" :model="form"  label-width="130px">
        <div class="col col4">
          <el-form-item label="模板代码" prop="templateCode" >
            <el-input v-model="form.templateCode" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="模板说明" prop="templateName" >
            <el-input v-model="form.templateName" placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="考评类型" prop="type" >
            <el-select v-model="form.type" placeholder="">
              <el-option label="标准考评" value="标准考评"></el-option>
            </el-select>
          </el-form-item>
        </div>
<!--         <div class="col col4">
          <el-form-item label="公司名称" prop="company" >
            <el-select v-model="form.company" placeholder="">
              <el-option label="中科曙光" value="中科曙光"></el-option>
            </el-select>
          </el-form-item>
        </div> -->
        <div class="col col4">
          <el-form-item label="是否允许重复考评" prop="isRepeat" >
            <el-checkbox v-model="form.isRepeat"></el-checkbox>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="是否启用" prop="status" >
            <el-checkbox v-model="form.status"></el-checkbox>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'

export default {
  name: 'EvaluateModelDialog',
  components: { ...Component },
  data: function () {
    return {
      form: {}
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform{
  .form{
    padding: 0px 0px;
  }
  .formlabel{
    :deep(.el-form-item__label){
      font-size: 14px !important;
    }
  }
}
</style>
